<template>
  <a-col class="h-100 overflow-hidden org">
    <a-button
      type="primary"
      class="back-btn"
      style="margin-top: 20px"
      @click="
        $router.push({
          name: 'headquarters',
          params: {
            type: $route.params.type
          }
        })
      "
    >
      <i class="t-transition icons icon-chevron-left" />
      Назад
    </a-button>

    <a-row class="content-title" type="flex">
      <h2 style="margin-bottom: 0">{{ title[$i18n.locale] }}</h2>

      <a-button
        style="margin-left: auto"
        type="primary"
        @click="
          $router.push({
            name: 'aboutAct-create',
            params: { ...$route.params }
          })
        "
      >
        <a-icon type="plus" />
        {{ $t("Add") }}
      </a-button>
    </a-row>

    <spinner v-if="loading" />

    <a-row v-else class="list-container menu-list__container thin-scroll">
      <Tree v-if="list.length > 0" :value="sortChildMenuList(list)">
        <a-row
          v-if="!node.isDragPlaceHolder"
          slot-scope="{ node, tree, path }"
          type="flex"
          align="middle"
        >
          <a-icon
            v-if="node.children && node.children.length"
            style="padding-right: 10px"
            :type="node.$folded ? 'plus' : 'minus'"
            @click="tree.toggleFold(node, path)"
          />

          <a-input-number
            v-model="node.position"
            style="width: 60px; margin-right: 10px"
            :min="1"
            @pressEnter="updatePosition(node, $event)"
          />

          <router-link
            :to="{
              name: 'aboutAct-update',
              params: { ...$route.params, id: node.id }
            }"
            class="td-post-item"
            tag="span"
          >
            <span>(ID: {{ node.id }}) {{ node.title || "Нет название" }}</span>
          </router-link>

          <a-row class="btns" type="flex" align="middle">
            <a-button
              type="primary"
              style="margin-right: 10px"
              class="action-btns"
              @click="
                $router.push({
                  name: 'aboutAct-docs',
                  params: {
                    about: node.id,
                    ...$route.params
                  }
                })
              "
            >
              Документы
            </a-button>

            <a-switch
              v-model="node.is_active"
              style="margin-right: 15px"
              @change="checkActive(node, $event)"
            />

            <a-tag
              style="min-width: 110px; text-align: center"
              :color="node.is_active ? 'geekblue' : 'volcano'"
            >
              {{ node.is_active ? $t("Published") : $t("NotPublished") }}
            </a-tag>

            <a-button
              type="primary"
              class="edit-btn"
              @click="
                $router.push({
                  name: 'aboutAct-update',
                  params: { ...$route.params, id: node.id }
                })
              "
            >
              <!--{{ $t("Edit") }}-->
              <a-icon type="edit" class="action-btns" />
            </a-button>

            <a-popconfirm
              title="Вы действительно хотите удалить?"
              ok-text="Да"
              cancel-text="Нет"
              @confirm="removeItem(node)"
            >
              <a-button style="margin-right: 10px" type="danger">
                <a-icon class="action-btns" type="delete" />
                <!--{{ $t("Delete") }}-->
              </a-button>
            </a-popconfirm>

            <a-row class="btns" type="flex-row" align="middle">
              <div>
                <b>{{ $t("TableCreatedDate") }}: </b>
                <span>{{ moment(node.created_at).format("LLL") }}</span>
              </div>

              <hr />

              <div>
                <b>{{ $t("TableLastUpdatedDate") }}: </b>
                <span>{{ moment(node.updated_at).format("LLL") }}</span>
              </div>
            </a-row>
          </a-row>
        </a-row>
      </Tree>
    </a-row>
  </a-col>
</template>

<script>
import "he-tree-vue/dist/he-tree-vue.css"
import { Tree, Fold } from "he-tree-vue"

export default {
  components: {
    Tree: Tree.mixPlugins([Fold])
    // breadthFirstSearch
  },

  data() {
    return {
      loading: false,
      list: [],
      title: {
        oz: "Департамент маълумотлари",
        uz: "Departament ma'lumotlari",
        qr: "Информация об отделе",
        ru: "Информация об отделе",
        en: "Информация об отделе"
      }
    }
  },
  computed: {},
  mounted() {
    this.fetchData()
  },
  methods: {
    async checkActive(item, event) {
      await this.$api.patch(
        `/admin/budget/activity-information/${item.id}/update/`,
        {
          is_active: event
        }
      )
    },
    async fetchData() {
      this.loading = true
      try {
        const data = await this.$api.get(
          "/admin/budget/activity-information/list/",
          {
            params: {
              activity: this.$route.params.act_id,
              parent: true
            }
          }
        )
        this.$set(this, "list", data.data)

        this.loading = false
      } catch (err) {
        console.error(err)
        this.$message.error("Ошибка при загрузке данных")
      }
      this.loading = false
    },
    async removeItem(arg) {
      try {
        const res = await this.$api.delete(
          `/admin/budget/activity-information/${arg.id}/delete/`
        )
        if (res) {
          this.$message.success("Успешно удален")
          await this.fetchData()
        } else {
          this.$message.error("Ошибка при удалении")
          throw "Не удалось удалить"
        }
        // console.log(res)
      } catch (err) {
        console.log(err)
      }
    },
    sortChildMenuList(list) {
      return (
        list.length > 0 &&
        list
          .map((item) => ({
            ...item,
            $folded: true,
            children:
              (item.children && this.sortChildMenuList(item.children)) || []
          }))
          .sort((a, b) => a.position - b.position)
      )
    },
    updatePosition(node, event) {
      this.loading = true
      this.$api
        .patch(`activity-information/${node.id}/update/`, {
          position: event.target.value
        })
        .then(() => {
          this.loading = false
          this.$message.success("Позиция обновлена")
          this.fetchData()
        })
        .catch((err) => {
          this.loading = false
          this.$message.error(err.message)
        })
    }
  }
}
</script>

<style lang="scss">
.tree-node-inner {
  padding: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 5px;
}

// .draggable-placeholder {
// }

.draggable-placeholder-inner {
  border: 1px dashed #0088f8;
  box-sizing: border-box;
  background: rgba(0, 136, 249, 0.09);
  color: #0088f9;
  text-align: center;
  padding: 0;
  display: flex;
  align-items: center;
}

.tree3 .tree-node-inner {
  border: none;
  padding: 0px;
}

.tree3 .tree-node-inner-back:hover {
  background: #ddd;
}

.tree4 .tree-node-inner {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
  background: #ccc;
}

.tree4 .draggable-placeholder-inner {
  background-color: orangered;
}
.he-tree .tree-node {
  padding: 8px 15px;
}
</style>
