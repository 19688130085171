var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-col',{staticClass:"h-100 overflow-hidden org"},[_c('a-button',{staticClass:"back-btn",staticStyle:{"margin-top":"20px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
        name: 'headquarters',
        params: {
          type: _vm.$route.params.type
        }
      })}}},[_c('i',{staticClass:"t-transition icons icon-chevron-left"}),_vm._v(" Назад ")]),_c('a-row',{staticClass:"content-title",attrs:{"type":"flex"}},[_c('h2',{staticStyle:{"margin-bottom":"0"}},[_vm._v(_vm._s(_vm.title[_vm.$i18n.locale]))]),_c('a-button',{staticStyle:{"margin-left":"auto"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
          name: 'aboutAct-create',
          params: { ..._vm.$route.params }
        })}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" "+_vm._s(_vm.$t("Add"))+" ")],1)],1),(_vm.loading)?_c('spinner'):_c('a-row',{staticClass:"list-container menu-list__container thin-scroll"},[(_vm.list.length > 0)?_c('Tree',{attrs:{"value":_vm.sortChildMenuList(_vm.list)},scopedSlots:_vm._u([{key:"default",fn:function({ node, tree, path }){return (!node.isDragPlaceHolder)?_c('a-row',{attrs:{"type":"flex","align":"middle"}},[(node.children && node.children.length)?_c('a-icon',{staticStyle:{"padding-right":"10px"},attrs:{"type":node.$folded ? 'plus' : 'minus'},on:{"click":function($event){return tree.toggleFold(node, path)}}}):_vm._e(),_c('a-input-number',{staticStyle:{"width":"60px","margin-right":"10px"},attrs:{"min":1},on:{"pressEnter":function($event){return _vm.updatePosition(node, $event)}},model:{value:(node.position),callback:function ($$v) {_vm.$set(node, "position", $$v)},expression:"node.position"}}),_c('router-link',{staticClass:"td-post-item",attrs:{"to":{
            name: 'aboutAct-update',
            params: { ..._vm.$route.params, id: node.id }
          },"tag":"span"}},[_c('span',[_vm._v("(ID: "+_vm._s(node.id)+") "+_vm._s(node.title || "Нет название"))])]),_c('a-row',{staticClass:"btns",attrs:{"type":"flex","align":"middle"}},[_c('a-button',{staticClass:"action-btns",staticStyle:{"margin-right":"10px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
                name: 'aboutAct-docs',
                params: {
                  about: node.id,
                  ..._vm.$route.params
                }
              })}}},[_vm._v(" Документы ")]),_c('a-switch',{staticStyle:{"margin-right":"15px"},on:{"change":function($event){return _vm.checkActive(node, $event)}},model:{value:(node.is_active),callback:function ($$v) {_vm.$set(node, "is_active", $$v)},expression:"node.is_active"}}),_c('a-tag',{staticStyle:{"min-width":"110px","text-align":"center"},attrs:{"color":node.is_active ? 'geekblue' : 'volcano'}},[_vm._v(" "+_vm._s(node.is_active ? _vm.$t("Published") : _vm.$t("NotPublished"))+" ")]),_c('a-button',{staticClass:"edit-btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
                name: 'aboutAct-update',
                params: { ..._vm.$route.params, id: node.id }
              })}}},[_c('a-icon',{staticClass:"action-btns",attrs:{"type":"edit"}})],1),_c('a-popconfirm',{attrs:{"title":"Вы действительно хотите удалить?","ok-text":"Да","cancel-text":"Нет"},on:{"confirm":function($event){return _vm.removeItem(node)}}},[_c('a-button',{staticStyle:{"margin-right":"10px"},attrs:{"type":"danger"}},[_c('a-icon',{staticClass:"action-btns",attrs:{"type":"delete"}})],1)],1),_c('a-row',{staticClass:"btns",attrs:{"type":"flex-row","align":"middle"}},[_c('div',[_c('b',[_vm._v(_vm._s(_vm.$t("TableCreatedDate"))+": ")]),_c('span',[_vm._v(_vm._s(_vm.moment(node.created_at).format("LLL")))])]),_c('hr'),_c('div',[_c('b',[_vm._v(_vm._s(_vm.$t("TableLastUpdatedDate"))+": ")]),_c('span',[_vm._v(_vm._s(_vm.moment(node.updated_at).format("LLL")))])])])],1)],1):_vm._e()}}],null,true)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }